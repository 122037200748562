import React, { useEffect, useMemo, useState } from 'react';
import CommonButton from '../../components/CommonButton';
import { Flex, Padding } from '../../assets/styles';
import styled from 'styled-components';
import CommonModal from '../../components/CommonModal';
import { TextareaAutosize } from '@mui/material';
import { getNotice, postNotice } from '../../apis/userService';

const Container = styled.div`
  height: 460px;
  width: fit-content;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  margin: auto;
`;

interface IProps {
  showModal: boolean;
  closeModal: () => void;
}

const InputModal = ({ showModal, closeModal }: IProps) => {
  const [val, setVal] = useState('');
  const [origin, setOrigin] = useState('');

  useEffect(() => {
    if (showModal)
      getNotice().then((notice) => {
        setOrigin(notice);
        setVal(notice);
      });
  }, [showModal]);

  const changed = useMemo(() => val !== origin, [val, origin]);

  const doSave = async () => {
    await postNotice(val);
    closeModal();
  };

  return (
    <CommonModal showModal={showModal} closeModal={closeModal}>
      <Container>
        <TextareaAutosize
          value={val}
          style={{ height: '400px', width: '500px', fontSize: '18px', overflow: 'auto' }}
          onChange={(e) => setVal(e.target.value)}
        />
        <Padding $val='12px' />
        <Flex $justify='flex-end'>
          <CommonButton label='닫기' onClick={closeModal} type='secondary' />
          <CommonButton label='저장' onClick={doSave} disabled={!changed} />
        </Flex>
      </Container>
    </CommonModal>
  );
};

export default InputModal;
