import React from 'react';
import styled from 'styled-components';
import { colors } from '../assets/styles';

const Container = styled.div``;
const Input = styled.input<{ $width: string }>`
  width: ${({ $width }) => $width};
  height: 28px;
  line-height: 28px;
  font-size: 16px;
  padding: 0 8px;
  border: solid 1px #dfe0e6;
  border-radius: 4px;

  &:focus {
    outline: none !important;
    border: 1px solid ${colors.hoverBg};
  }

  &:hover {
    border-color: ${colors.hoverBg};
    cursor: text;
  }
`;

interface InputProps {
  width: string;
  value: string | number | undefined;
  changingValue: (arg0: string) => void;
  placeholder?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  onEnter?: () => void;
  type?: string;
}

const CommonInput = ({
  width,
  value,
  changingValue,
  placeholder,
  onBlur,
  onFocus,
  onEnter,
  type,
}: InputProps) => {
  const onKeyDown = (e: { key: string }) => {
    if (e.key === 'Enter') {
      onEnter?.();
    }
  };
  return (
    <Container>
      <Input
        $width={width}
        value={value}
        placeholder={placeholder}
        onChange={(e) => changingValue(e.target.value)}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        type={type}
      />
    </Container>
  );
};

export default CommonInput;
