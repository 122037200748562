import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE;
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Authorization'] = 'Bearer testtoken';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    console.log('!!err: ', err);
    if (!err.response) return Promise.reject(err);
    const {
      response: { status },
      config,
    } = err;
    if (config.url === '/v1/login' || config._retry) return Promise.reject(err);

    switch (status) {
      case 403:
      case 401:
        if (config.url === '/v1/refresh') {
          window.location.href = '/login?status=unauth';
          return;
        }

        try {
          await axios.post('/v1/refresh');

          config._retry = true;
          return axios.request(config);
        } catch (e) {
          console.log('error', e);
        }
        break;
      default:
        alert(err);
    }

    return Promise.reject(err);
  },
);

export default axios;
