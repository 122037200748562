import React from 'react';
import { Text } from '../assets/styles';
import CommonCheckbox from './CommonCheckbox';

interface IProps {
  doCheck?: () => void;
  val: boolean | undefined;
  label: string;
  disabled?: boolean;
}
const CommonCheckboxLabel = ({ doCheck, val, label, disabled }: IProps) => {
  const clickLabel = () => {
    if (!disabled) doCheck?.();
  };
  return (
    <>
      <CommonCheckbox val={val} doCheck={doCheck} disabled={disabled} />
      <Text $margin='0 8px 0 0' $hover onClick={clickLabel}>
        {label}
      </Text>
    </>
  );
};

export default CommonCheckboxLabel;
