import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';

import { colors, Flex, Padding } from '../../assets/styles';
import { postLogout } from '../../apis/userService';
import { ReactComponent as AlertIcon } from '../../assets/alert.svg';
import NoticeModal from '../modal/NoticeModal';
import { Spacing } from '../../components/Common';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  min-width: 1600px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: ${colors.primary};
`;

const Label = styled.div`
  font-size: 18px;
  color: #fff;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`;

const ActionGroups = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TextButton = styled.div`
  color: #ffffff;
  font-size: 18px;
  //border: solid 1px #8e8eb4;
  //border-radius: 4px;
  padding: 2px 4px;

  &:hover {
    cursor: pointer;
  }
`;

const NaviBar = (): ReactElement => {
  const [showNotice, setShowNotice] = useState(false);
  const navigate = useNavigate();

  const refresh = () => {
    window.location.href = '/';
  };

  const doLogout = async () => {
    await postLogout();

    window.location.href = '/login';
  };

  const moveToMobile = () => {
    location.href = 'https://m.woorimco.com';
  };

  return (
    <>
      <Container>
        <ActionGroups>
          <Label onClick={refresh}>W_ONAire</Label>
          <Padding $width={'12px'} style={{}} />
          <AlertIcon
            onClick={() => setShowNotice(true)}
            width={'26px'}
            height={'26px'}
            fill={'white'}
          />
          <Padding $width={'10px'} style={{}} />
          <IconButton onClick={moveToMobile}>
            <PhoneIphoneIcon sx={{ color: '#fff' }} />
          </IconButton>
          <Spacing $val={'40px'} />
          <Flex>
            <TextButton onClick={() => navigate('/search')}>Search</TextButton>
            <Spacing $val={'8px'} />
            <TextButton onClick={() => navigate('/list')}>List</TextButton>
            <Spacing $val={'8px'} />
            <hr style={{ height: '18px', marginRight: '12px' }} />
            <TextButton onClick={doLogout}>Logout</TextButton>
          </Flex>
        </ActionGroups>
      </Container>
      <NoticeModal closeModal={() => setShowNotice(false)} showModal={showNotice} />
    </>
  );
};

export default NaviBar;
