import React, { ReactElement } from 'react';
import NaviBar from './gnb/NaviBar';
import { Outlet } from 'react-router-dom';

const MainView = (): ReactElement => {
  return (
    <div>
      <NaviBar />
      <Outlet />
    </div>
  );
};

export default MainView;
