import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Text } from '../../assets/styles';

interface IProps {
  emails: string[];
  selectedVal: string[];
  selEmail: (arg: string[]) => void;
}

const SelectDomain = ({ emails, selectedVal, selEmail }: IProps) => {
  return (
    <>
      <Text $margin='0 8px 0 12px'>Email</Text>
      <Autocomplete
        disablePortal
        value={selectedVal}
        multiple
        options={emails}
        sx={{ width: 250 }}
        onChange={(e: any, newVal: string[]) => {
          selEmail(newVal);
        }}
        renderInput={(params) => <TextField {...params} size='small' />}
      />
    </>
  );
};

export default SelectDomain;
