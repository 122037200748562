import { toast } from 'react-toastify';

const toastError = (msg: string) => {
  toast(msg, { type: 'error', position: 'bottom-center' });
};

const toastInfo = (msg: string) => {
  toast(msg, { position: 'bottom-center' });
};

export { toastError, toastInfo };
