import React from 'react';
import PageContainer from '../components/PageContainer';
import CommonButton from '../components/CommonButton';
import { useNavigate } from 'react-router-dom';

const ErrorView = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  };

  return (
    <PageContainer>
      <div>Server Side Error</div>
      <CommonButton onClick={goHome} label='Goto Home' />
    </PageContainer>
  );
};

export default ErrorView;
