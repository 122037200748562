import React, { useMemo } from 'react';
import { Flex, FlexColumn, Padding, Text } from '../../assets/styles';
import SelectArea from './SelectArea';
import SelectCountry from './SelectCountry';
import SelectDomain from './SelectDomain';
import ISearchParam, { IExcluSearch } from '../../interfaces/ISearchParam';
import { setParams } from './SearchUtil';
import CommonInput from '../../components/CommonInput';
import { Autocomplete, TextField } from '@mui/material';

interface IProps {
  domains: string[];
  searchParams: ISearchParam;
  setSearchParams: (arg: ISearchParam) => void;
  specializedList: string[];
}

const SearchExcludCondArea = ({
  specializedList,
  domains,
  searchParams,
  setSearchParams,
}: IProps) => {
  const setSelParam = (
    key: IExcluSearch,
    value: string | string[] | null | undefined,
    replace = true,
  ) => {
    setParams('excluCond', searchParams, setSearchParams, { key, value: value || [] }, replace);
  };

  const options = useMemo(() => {
    return specializedList.map((str) => ({
      label: str,
      id: str,
    }));
  }, [specializedList]);

  const getValue = useMemo(() => {
    return options.filter(({ id }) => searchParams.excluCond?.specialized?.includes(id));
  }, [searchParams.excluCond.specialized]);

  return (
    <Flex $alignItems={'unset'} $borderBottom={'solid 1px #e2e2e2'}>
      <Text $margin='0 12px 0 0' $width='90px' $backcolor={'lightgray'} $center>
        EXCLUDING
      </Text>
      <FlexColumn $alignItems={'flex-start'} $padding={'12px 0'}>
        <Flex>
          <SelectArea
            showLabel
            multiple
            selectedVal={searchParams.excluCond.area || []}
            selArea={(val) => setSelParam('area', val)}
          />
          <SelectCountry
            selectedVal={searchParams.excluCond.country || []}
            selCountry={(val) => setSelParam('country', val)}
          />
          <SelectDomain
            selectedVal={searchParams.excluCond.domain || []}
            domains={domains}
            selDomain={(val) => setSelParam('domain', val)}
          />
        </Flex>
        <Padding $val='8px' />
        <Flex>
          <Text $margin='0 8px 0 12px'>제외메일</Text>
          <CommonInput
            width={'200px'}
            placeholder={'a@test.com,b@test.com'}
            value={searchParams.excluCond.excludeEmails?.join(',')}
            changingValue={(val) => {
              setSelParam(
                'excludeEmails',
                val.split(',').map((email) => email.trim()),
              );
            }}
          />
          <Text $margin='0 8px 0 12px'>BESPOKE</Text>
          <Autocomplete
            disablePortal
            multiple
            value={getValue}
            sx={{ width: 500 }}
            renderInput={(params) => <TextField {...params} size='small' />}
            options={options}
            onChange={(e, newVal: any) => {
              if (Array.isArray(newVal)) {
                setSelParam(
                  'specialized',
                  newVal.map(({ id }) => id),
                );
              } else {
                setSelParam('specialized', newVal.id);
              }
            }}
          />
        </Flex>
      </FlexColumn>
    </Flex>
  );
};

export default SearchExcludCondArea;
