import React from 'react';
import styled from 'styled-components';
import { usePromiseTracker } from 'react-promise-tracker';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: rgba(211, 211, 211, 0.13);
  z-index: 9999;
`;

const Loading = () => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    <>
      {promiseInProgress && (
        <Container>
          <img src={require('../assets/loading-bar.gif')} width='200px' height='200px' />
        </Container>
      )}
    </>
  );
};

export default Loading;
