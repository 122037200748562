import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import CommonInput from '../components/CommonInput';
import CommonSelect from '../components/CommonSelect';
import { IWoorimInitState } from '../interfaces/common';
import { IField, IFieldType, ISelectOption } from '../interfaces/fields';
import { useDispatch, useSelector } from 'react-redux';
import { setFieldMeta } from '../redux/woorimActions';
import PageContainer from '../components/PageContainer';
import CommonButton from '../components/CommonButton';

const FieldRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.div`
  height: 40px;
  width: 30px;
  line-height: 40px;
`;

const Div = styled.div`
  width: 8px;
`;

const ActionGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
`;

const EmailConfigFieldView = () => {
  const originFields = useSelector((state: IWoorimInitState) => state.fieldMeta);
  const [fields, setFields] = useState<IField[]>([]);
  useEffect(() => {
    setFields(JSON.parse(JSON.stringify(originFields)) as IField[]);
  }, [originFields]);
  const dispatch = useDispatch();
  const setName = (idx: number, val: string) => {
    setFields(
      fields.map((field, fidx) => {
        if (idx === fidx) field.name = val;
        return field;
      }),
    );
  };
  const setType = (idx: number, val: IFieldType) => {
    setFields(
      fields.map((field, fidx) => {
        if (idx === fidx) field.type = val;
        return field;
      }),
    );
  };
  const fieldTypes = useMemo<ISelectOption[]>(() => {
    return [
      { key: 'INPUT', value: 'INPUT' },
      { key: 'SELECT', value: 'SELECT' },
      { key: 'AUTO', value: 'AUTO' },
    ];
  }, []);

  const setOptions = (idx: number, value: string) => {
    setFields(
      fields.map((field, fidx) => {
        if (idx === fidx) field.options = value;
        return field;
      }),
    );
  };

  // TODO: save 전에 string 으로 변경 값 비교해주기
  const doSave = () => {
    dispatch(setFieldMeta(fields));
  };

  return (
    <PageContainer title='Config Meta'>
      <ActionGroup>
        <CommonButton label='SAVE' onClick={doSave} />
      </ActionGroup>
      {fields.map((field, idx) => (
        <FieldRow key={idx}>
          <Label>{`#${idx + 1}`}</Label>
          <Div />
          <CommonInput
            width='150px'
            value={field.name}
            placeholder='field name'
            changingValue={(val) => setName(idx, val)}
          />
          <Div />
          <CommonSelect
            width='100px'
            options={fieldTypes}
            selectOption={(val) => setType(idx, val as IFieldType)}
            selectedOption={field.type}
          />
          {field.type === 'SELECT' && (
            <>
              <Div />
              <CommonInput
                width='200px'
                value={field.options}
                placeholder='A|B|C'
                changingValue={(val) => setOptions(idx, val)}
              />
            </>
          )}
        </FieldRow>
      ))}
    </PageContainer>
  );
};

export default EmailConfigFieldView;
