import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors, Flex, Text } from '../../assets/styles';
import CommonInput from '../../components/CommonInput';
import { Spacing } from '../../components/Common';
import CommonButton from '../../components/CommonButton';
import { postLogin } from '../../apis/userService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toastError } from '../../utils/toastUtil';
import CommonCheckbox from '../../components/CommonCheckbox';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-5NHY3V5LWX');

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginArea = styled.div`
  height: 500px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: solid 1px lightgrey;
  border-radius: 4px;
  padding: 20px;
`;

const Logo = styled.div`
  color: ${colors.primary};
  font-size: 40px;
  text-align: center;
  font-weight: 700;
`;

const hover = css`
  &:hover {
    cursor: pointer;
  }
`;

const LoginView = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [keepLogin, setKeepLogin] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('status') === 'unauth') toastError('인증이 만료되었습니다.');
    setSearchParams({});
  }, [searchParams]);

  const doLogin = async () => {
    try {
      ReactGA.set({
        userId,
      });

      ReactGA.event({
        category: 'login',
        action: 'try login',
        label: userId,
      });

      await postLogin({ userId, password, keepLogin });
      navigate('/');
    } catch (e) {
      toastError('로그인을 실패했습니다.');
    }
  };

  const doCheckKeepLogin = () => {
    setKeepLogin((prev) => !prev);
  };

  return (
    <Container>
      <LoginArea>
        <Logo>WOORIM</Logo>
        <Spacing $val={'45px'} />
        <CommonInput
          width={'100%'}
          value={userId}
          changingValue={(val) => setUserId(val)}
          placeholder={'ID'}
        />
        <Spacing $val={'8px'} />
        <CommonInput
          width={'100%'}
          value={password}
          changingValue={(val) => setPassword(val)}
          placeholder={'PW'}
          onEnter={doLogin}
          type={'password'}
        />
        <Spacing $val={'12px'} />
        <Flex>
          <CommonCheckbox val={keepLogin} doCheck={doCheckKeepLogin} />
          <Text onClick={doCheckKeepLogin} $hover>
            로그인 상태 유지
          </Text>
        </Flex>
        <Spacing $val={'12px'} />
        <CommonButton label={'로그인'} onClick={doLogin} />
      </LoginArea>
    </Container>
  );
};

export default LoginView;
