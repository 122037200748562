import { IConfirm } from '../interfaces/common';

const Area: string[] = [
  'FEAST/SEASIA',
  'AUST/NZ',
  'RSEA/AG/INDIA/S.AFRICA',
  'CONT/MED/BSEA/W.AFR',
  'EC CANADA/USATL/CARIB/NCSA/ECSA',
  'WC CANADA/USWC/WCCA/WCSA',
  'WORLDWIDE',
];

const Country: string[] = ['KOREA', 'US', 'UK'];

const initConfirm: IConfirm = {
  show: false,
  msg: '',
  doConfirm: () => {},
};

export { Area, Country, initConfirm };
