import React from 'react';
import styled from 'styled-components';
import { Flex, Padding, Text } from '../../assets/styles';
import CommonButton from '../../components/CommonButton';
import ISearchParam from '../../interfaces/ISearchParam';
import { Spacing } from '../../components/Common';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

interface IProps {
  searchParams: ISearchParam;
  setSearchParams: (arg: ISearchParam) => void;
  exportExcel: () => void;
  copyEmails: () => void;
  sendPersonally: () => void;
  addSpecialized: () => void;
  delSpecialized: () => void;
  editSpecialized: () => void;
  delEmail: () => void;
  addEmail: () => void;
  doSearch: () => void;
  addSpecializedEmails: () => void;
}

const ActionArea = ({
  exportExcel,
  copyEmails,
  sendPersonally,
  addSpecialized,
  delSpecialized,
  editSpecialized,
  delEmail,
  addEmail,
  addSpecializedEmails,
}: IProps) => {
  return (
    <>
      <Container>
        <Spacing $val={'12px'} />
        <Flex $padding={'4px'} $backgroundColor={'lightgray'}>
          <Text>EMAIL</Text>
          <Spacing $val={'4px'} />
          <CommonButton label={'새 연락처'} onClick={addEmail} type={'secondary'} />
          <CommonButton label={'DEL'} onClick={delEmail} type={'secondary'} />
        </Flex>
        <Padding $width={'12px'} />
        <Flex $padding={'4px'} $backgroundColor={'lightgray'}>
          <Text>BESPOKE</Text>
          <Spacing $val={'4px'} />
          <CommonButton label={'ADD'} onClick={addSpecialized} type={'secondary'} />
          <CommonButton label={'EDIT'} onClick={editSpecialized} type={'secondary'} />
          <CommonButton label={'DEL'} onClick={delSpecialized} type={'secondary'} />
          <Spacing $val={'8px'} />
          <CommonButton label={'ADD EMAILS'} onClick={addSpecializedEmails} type={'secondary'} />
        </Flex>
        <Spacing $val={'12px'} />
        <CommonButton label='Excel' onClick={exportExcel} type='secondary' />
        <CommonButton label='BCC Copy' onClick={copyEmails} />
        <CommonButton label='Personal Copy' onClick={sendPersonally} />
      </Container>
    </>
  );
};
export default ActionArea;
ActionArea.displayName = 'ActionArea';
