import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Area } from '../../const/common';
import { Text } from '../../assets/styles';

interface IProps {
  selectedVal: string[] | string;
  selArea: (arg: string[] | string | null) => void;
  multiple?: boolean;
  showLabel?: boolean;
  disabled?: boolean;
}

const SelectArea = ({ disabled, selectedVal, selArea, multiple, showLabel }: IProps) => {
  return (
    <>
      {showLabel && <Text $margin='0 8px 0 12px'>Area</Text>}
      <Autocomplete
        disabled={disabled}
        disablePortal
        multiple={multiple}
        value={selectedVal}
        options={Area}
        sx={{ width: 350 }}
        onChange={(e, newVal) => {
          selArea(newVal);
        }}
        onInputChange={(event, newInputValue) => {
          console.log('');
        }}
        renderInput={(params) => <TextField {...params} size='small' />}
      />
    </>
  );
};

export default SelectArea;

SelectArea.defaultProps = {
  SelectArea: false,
};
