import React, { useEffect, useState } from 'react';
import CommonModal from '../../components/CommonModal';
import { IWoorimInitState } from '../../interfaces/common';
import styled, { css } from 'styled-components';
import { Flex, FlexColumn, Text } from '../../assets/styles';
import { useSelector } from 'react-redux';
import CommonInput from '../../components/CommonInput';
import CommonButton from '../../components/CommonButton';
import EmailVO from '../../interfaces/EmailVO';
import SelectCountry from '../search/SelectCountry';
import CommonSelect2 from '../../components/CommonSelect2';
import SelectArea from '../search/SelectArea';
import { Spacing } from '../../components/Common';
import { delEmail, getEmail, postEmail, putEmail } from '../../apis/emailService';
import CommonCheckbox from '../../components/CommonCheckbox';
import ConfirmModal from './ConfirmModal';
import Quill from 'quill';
const Link = Quill.import('formats/link');
Link.sanitize = function (url: string) {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
};
import ReactQuill from 'react-quill';

const EditModalContainer = styled.div`
  width: 600px;
  height: 700px;
`;

const BottomActionArea = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface IProps {
  showModal: boolean;
  closeModal: () => void;
  refresh: () => void;
  email: EmailVO;
  mode: string;
}

const EmailModifierModal = ({ showModal, closeModal, email, mode, refresh }: IProps) => {
  const [form, setForm] = useState<EmailVO>({ ...initForm });
  const [enableCircular, setEnableCircular] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const countryMeta = useSelector((state: IWoorimInitState) => state.countries);

  useEffect(() => {
    if (mode === 'EDIT' && showModal) {
      setEnableCircular(false);
      getEmail(email.id).then((res) => setForm(res));
    } else {
      setEnableCircular(false);
      setForm({ ...email });
    }
  }, [email, showModal]);

  const setFormValue = (key: string, val: string) => {
    if (key === 'country') {
      const area = countryMeta.find(({ code }) => code === val)?.area;
      if (area) setFormValue('area', area);
    }

    setForm((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const doSave = async () => {
    if (mode === 'EDIT') await putEmail(form);
    else await postEmail(form);
    await refresh();
    closeModal();
  };

  const doDelEmail = async () => {
    if (mode === 'EDIT') await delEmail([form.id]);
    await refresh();
    closeModal();
  };

  return (
    <CommonModal showModal={showModal} closeModal={closeModal}>
      <EditModalContainer>
        <Text $size={'20px'}>{mode}</Text>
        <Spacing $val={'12px'} />
        <FlexColumn $alignItems={'flex-start'}>
          <FormRow>
            <FormLabel>name</FormLabel>
            <CommonInput
              width={'300px'}
              value={form.name}
              changingValue={(val) => setFormValue('name', val)}
            />
          </FormRow>
          <FormRow>
            <FormLabel required>email</FormLabel>
            <CommonInput
              width={'300px'}
              value={form.email}
              changingValue={(val) => setFormValue('email', val)}
            />
          </FormRow>
          <FormRow>
            <FormLabel>company</FormLabel>
            <CommonInput
              width={'300px'}
              value={form.company}
              changingValue={(val) => setFormValue('company', val)}
            />
          </FormRow>
          <FormRow>
            <FormLabel required>country</FormLabel>
            <SelectCountry
              showLabel={false}
              multiple={false}
              selectedVal={form.country}
              selCountry={(val) => setFormValue('country', val as string)}
            />
          </FormRow>
          <FormRow>
            <FormLabel required>type</FormLabel>
            <CommonSelect2
              multiple={false}
              showLabel={false}
              selectedVal={form.type}
              selCountry={(val) => setFormValue('type', val as string)}
              options={typeOptions}
            />
          </FormRow>
          <FormRow>
            <FormLabel required>location</FormLabel>
            <CommonSelect2
              multiple={false}
              showLabel={false}
              selectedVal={form.location}
              selCountry={(val) => setFormValue('location', val as string)}
              options={locationOptions}
            />
          </FormRow>
          <FormRow>
            <FormLabel>area</FormLabel>
            <SelectArea
              disabled
              multiple={false}
              showLabel={false}
              selectedVal={form.area}
              selArea={(val) => setFormValue('area', val as string)}
            />
          </FormRow>
        </FlexColumn>
        <FormRow>
          <FormLabel>bespoke</FormLabel>
          <CommonInput
            width={'480px'}
            value={form.specialized}
            changingValue={(val) => setFormValue('specialized', val)}
          />
        </FormRow>
        <FormRow>
          <FormLabel>memo</FormLabel>
          <Flex $height={'178px'} $alignItems={'start'}>
            <ReactQuill
              style={{ height: '160px', width: '480px' }}
              value={form.memo || ''}
              onChange={(value) => setFormValue('memo', value)}
            />
          </Flex>
        </FormRow>
        <Spacing $val={'12px'} />
        <FormRow>
          <FormLabel>circulars</FormLabel>
          <CommonSelect2
            multiple={false}
            showLabel={false}
            selectedVal={form.circulars}
            selCountry={(val) => setFormValue('circulars', val as string)}
            options={circularOptions}
            disabled={!enableCircular}
          />
          <CommonCheckbox val={enableCircular} doCheck={() => setEnableCircular((prev) => !prev)} />
        </FormRow>
      </EditModalContainer>
      <BottomActionArea>
        {mode === 'EDIT' && (
          <>
            <CommonButton label='DEL' onClick={() => setShowConfirm(true)} type='secondary' />
            <div style={{ width: '1px', backgroundColor: 'grey', margin: '4px 8px 4px 4px' }} />
          </>
        )}
        <CommonButton label='CANCEL' onClick={closeModal} type='secondary' />
        <CommonButton label='SAVE' onClick={doSave} />
      </BottomActionArea>
      <ConfirmModal
        showModal={showConfirm}
        closeModal={() => setShowConfirm(false)}
        msg={'삭제하시겠습니까?'}
        clickConfirm={doDelEmail}
      />
    </CommonModal>
  );
};

export default EmailModifierModal;

const FormRow = styled(Flex)`
  padding-bottom: 8px;
`;

const FormLabel = styled(Text)<{ required?: boolean }>`
  width: 100px;
  text-align: start;

  ${({ required }) =>
    required &&
    css`
      &:after {
        content: ' *';
        color: red;
      }
    `}
`;

const typeOptions = ['DIRECT', 'BROKERS', 'LOCALS'];
const locationOptions = ['WEST', 'EAST', 'WW'];

const circularOptions = [
  'A-GRADE',
  'B-GRADE',
  'C-GRADE',
  'D-GRADE',
  'POSITION',
  'ORDER',
  'OFTEN',
  'BOTH',
];

export const initForm: EmailVO = {
  id: 0,
  name: '',
  email: '',
  company: '',
  country: '',
  type: '',
  circulars: '',
  location: '',
  area: '',
  specialized: '',
  remark: '',
  memo: '',
};
