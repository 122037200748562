import React, { ElementRef, forwardRef, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/styles';
import ISearchParam from '../../interfaces/ISearchParam';
import SearchRegionArea from './SearchRegionArea';
import SearchAddCondArea from './SearchAddCondArea';
import SearchExcludCondArea from './SearchExcludCondArea';
import SearchSpecialCondArea from './SearchSpecialCondArea';

const SearchConditionContainer = styled.div`
  min-width: 1000px;
  padding: 0;
`;

const RegionTopArea = styled.div`
  display: flex;
`;

const RegionHeader = styled.div`
  width: 150px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  padding-left: 8px;
  background-color: ${colors.primary};
`;

const HeaderPad = styled.div`
  width: 400px;
`;

interface IProps {
  emails: string[];
  domains: string[];
  specializedList: string[];
  searchParams: ISearchParam;
  setSearchParams: (arg: ISearchParam) => void;
  doSearch: () => void;
}

interface refImperative {
  resetSelect: () => void;
}

const SearchCondition = forwardRef<refImperative, IProps>(
  ({ emails, domains, searchParams, setSearchParams, specializedList, doSearch }, ref) => {
    const SearchSpecialCondAreaRef = useRef<ElementRef<typeof SearchSpecialCondArea>>(null);

    useImperativeHandle(ref, () => ({
      resetSelect: () => {
        SearchSpecialCondAreaRef?.current?.resetSelect();
      },
    }));

    return (
      <SearchConditionContainer>
        <RegionTopArea>
          <RegionHeader>해외</RegionHeader>
          <HeaderPad />
          <RegionHeader>국내 Local</RegionHeader>
        </RegionTopArea>
        <SearchRegionArea searchParams={searchParams} setSearchParams={setSearchParams} />
        <div style={{ height: '8px' }} />
        <SearchAddCondArea
          emails={emails}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
        <SearchExcludCondArea
          specializedList={specializedList}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          domains={domains}
        />
        <SearchSpecialCondArea
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          specializedList={specializedList}
          doSearch={doSearch}
          ref={SearchSpecialCondAreaRef}
        />
      </SearchConditionContainer>
    );
  },
);

export default SearchCondition;
SearchCondition.displayName = 'SearchCondition';
