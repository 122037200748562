import React from 'react';
import { Flex, Text } from '../../assets/styles';
import SelectArea from './SelectArea';
import SelectCountry from './SelectCountry';
import ISearchParam, { IAddSearch } from '../../interfaces/ISearchParam';
import { setParams } from './SearchUtil';
import SelectEmail from './SelectEmail';

interface IProps {
  searchParams: ISearchParam;
  setSearchParams: (arg: ISearchParam) => void;
  emails: string[];
}

const SearchAddCondArea = ({ searchParams, setSearchParams, emails }: IProps) => {
  const setSelParam = (key: IAddSearch, value: string | string[] | null | undefined) => {
    setParams('addCond', searchParams, setSearchParams, { key, value: value || [] }, true);

    if (['area', 'country'].includes(key) && Array.isArray(value) && value.length > 0) {
      setParams('abroad', searchParams, setSearchParams, { key: 'location', value: [] }, true);
    }
  };

  return (
    <Flex $alignItems={'unset'} $borderBottom={'solid 1px #e2e2e2'}>
      <Text $margin='0 12px 0 0' $width='90px' $backcolor={'lightgray'} $center>
        INCLUDING
      </Text>
      <Flex $padding={'12px 0'}>
        <SelectArea
          multiple
          showLabel
          selectedVal={searchParams.addCond.area || []}
          selArea={(val) => setSelParam('area', val)}
        />
        <SelectCountry
          selectedVal={searchParams.addCond.country || []}
          selCountry={(val) => setSelParam('country', val)}
        />
        <SelectEmail
          selectedVal={searchParams.addCond.email || []}
          emails={emails}
          selEmail={(val) => setSelParam('email', val)}
        />
      </Flex>
    </Flex>
  );
};

export default SearchAddCondArea;
