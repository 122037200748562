import React from 'react';
import styled from 'styled-components';
import { colors } from '../assets/styles';

const Check = styled.input`
  width: 18px;
  height: 18px;
  accent-color: ${colors.primary};

  &:hover {
    cursor: pointer;
  }
`;

interface IProps {
  doCheck?: () => void;
  val: boolean | undefined;
  disabled?: boolean;
}
const CommonCheckbox = ({ doCheck, val, disabled }: IProps) => {
  const changeValue = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (doCheck) doCheck();
  };

  const doNothing = () => {
    console.log('');
  };

  return (
    <Check
      type='checkbox'
      checked={val}
      onClick={(e) => changeValue(e)}
      onChange={doNothing}
      disabled={disabled}
    ></Check>
  );
};

export default CommonCheckbox;
