import React, { ReactElement } from 'react';
import MainView from './views/MainView';
import { Route, Routes } from 'react-router-dom';
import EmailSearchView from './views/EmailSearchView';
import EmailConfigFieldView from './views/EmailConfigFieldView';
import EmailView from './views/EmailView';
import { Provider } from 'react-redux';
import store from './redux/store';
import Loading from './views/Loading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginView from './views/user/LoginView';
import EmailListView from './views/list/EmailListView';
import ErrorView from './views/ErrorView';

const App = (): ReactElement => {
  return (
    <Provider store={store}>
      <ToastContainer />
      <Loading />
      <Routes>
        <Route path='/login' element={<LoginView />} />
        <Route path='/error' element={<ErrorView />} />
        <Route path='/' element={<MainView />}>
          <Route path='' element={<EmailSearchView />} />
          <Route path='/search' element={<EmailSearchView />} />
          <Route path='/list' element={<EmailListView />} />
          <Route path='/emails' element={<EmailView />} />
          <Route path='/config-field' element={<EmailConfigFieldView />} />
        </Route>
      </Routes>
    </Provider>
  );
};

export default App;
