import React, { useEffect, useMemo, useState } from 'react';
import CommonModal from '../../components/CommonModal';
import EmailTable from '../../components/EmailTable';
import { getEmails } from '../../apis/emailService';
import ISearchParam from '../../interfaces/ISearchParam';
import { initSearchParams } from '../EmailSearchView';
import EmailVO from '../../interfaces/EmailVO';
import { colors, Flex, Padding, Text } from '../../assets/styles';
import CommonButton from '../../components/CommonButton';
import CommonInput from '../../components/CommonInput';

interface IProps {
  showModal: boolean;
  closeModal: () => void;
  bespoke: string;
  saveSpecialized: ({
    specialized,
    targetEmails,
  }: {
    specialized: string;
    targetEmails: number[];
  }) => void;
}

const AddSpecializedEmailModal = ({ saveSpecialized, showModal, closeModal, bespoke }: IProps) => {
  const [emails, setEmails] = useState<EmailVO[]>([]);
  const [originEmails, setOriginEmails] = useState<EmailVO[]>([]);
  const [totalCnt, setTotalCnt] = useState(0);
  const [searchParams] = useState<ISearchParam>(JSON.parse(JSON.stringify(initSearchParams)));
  const [targetEmails, setTargetEmails] = useState<EmailVO[]>([]);
  const [specialized, setSpecialized] = useState<string>('');
  const [filterKeyword, setFilterKeyword] = useState<string>('');

  useEffect(() => {
    if (showModal) {
      getEmails({ searchParams }).then(({ emails, totalCnt }) => {
        setEmails(emails);
        setOriginEmails(emails);
        setTotalCnt(totalCnt);
      });
      setTargetEmails([]);
      setFilterKeyword('');
      if (!bespoke) setSpecialized('');
    }
  }, [showModal]);

  useEffect(() => {
    setSpecialized(bespoke);
  }, [bespoke]);

  const selEmailList = useMemo(() => {
    return targetEmails.map(({ email }) => email).join(', ');
  }, [targetEmails]);

  const filterEmails = () => {
    if (!filterKeyword) {
      setEmails(originEmails);
    } else {
      setEmails(
        originEmails.filter(
          ({ email, name, company }) =>
            email?.toUpperCase().includes(filterKeyword.toUpperCase()) ||
            name?.toUpperCase().includes(filterKeyword.toUpperCase()) ||
            company?.toUpperCase().includes(filterKeyword.toUpperCase()),
        ),
      );
    }
  };

  const addEmail = (selEmail: EmailVO) => {
    if (targetEmails.findIndex(({ email }) => email === selEmail.email) >= 0) {
      setTargetEmails((prev) => prev.filter(({ email }) => email !== selEmail.email));
    } else {
      setTargetEmails((prev) => [...prev, selEmail]);
    }
  };

  return (
    <CommonModal showModal={showModal} closeModal={closeModal}>
      <Text $size='18px'>Add Bespoke Emails</Text>
      <Padding $val={'20px'} />
      <Flex>
        <Text $size='18px'>Bespoke</Text>
        <Padding $width={'8px'} />
        <CommonInput
          width={'200px'}
          value={specialized}
          changingValue={(val) => setSpecialized(val)}
        />
        <Padding $width={'8px'} />
        <Text $size='12px'>Filter emails by</Text>
        <Padding $width={'8px'} />
        <CommonInput
          width={'200px'}
          value={filterKeyword}
          changingValue={(val) => setFilterKeyword(val)}
          onEnter={filterEmails}
        />
      </Flex>
      <Padding $val={'8px'} />
      <Text $size='18px' $color={colors.primary} $width={'800px'}>
        {selEmailList}
      </Text>
      <Padding $val={'20px'} />
      <div style={{ width: '800px', overflow: 'auto' }}>
        <EmailTable
          emails={emails}
          totalCnt={totalCnt}
          sortEmails={() => {}}
          rowDoubleClick={addEmail}
          checkable={false}
        />
      </div>
      <Padding $val={'20px'} />
      <Flex $justify='flex-end'>
        <CommonButton label='취소' onClick={closeModal} type='secondary' />
        <CommonButton
          label='추가'
          onClick={() =>
            saveSpecialized({ specialized, targetEmails: targetEmails.map(({ id }) => id) })
          }
          type='primary'
        />
      </Flex>
    </CommonModal>
  );
};

export default AddSpecializedEmailModal;
