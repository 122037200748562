import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Text } from '../../assets/styles';

interface IProps {
  domains: string[];
  selectedVal: string[];
  selDomain: (arg: string[]) => void;
}

const SelectDomain = ({ domains, selectedVal, selDomain }: IProps) => {
  return (
    <>
      <Text $margin='0 8px 0 12px'>Domain</Text>
      <Autocomplete
        disablePortal
        value={selectedVal}
        multiple
        options={domains}
        sx={{ width: 250 }}
        onChange={(e: any, newVal: string[]) => {
          selDomain(newVal);
        }}
        renderInput={(params) => <TextField {...params} size='small' />}
      />
    </>
  );
};

export default SelectDomain;
