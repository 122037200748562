import React, { useMemo } from 'react';
import { colors, Flex, Padding, Text } from '../../assets/styles';
import styled from 'styled-components';
import ISearchParam, { IAbroadSearch } from '../../interfaces/ISearchParam';
import { ICirculars, ILocation, IType } from '../../interfaces/EmailVO';
import CommonCheckboxLabel from '../../components/CommonCheckboxLabel';
import { Spacing } from '../../components/Common';

const RegionBodyArea = styled.div`
  display: flex;
`;

const Conditions = styled.div`
  height: 70px;
  width: 550px;
  padding-top: 8px;
  border-left: solid 2px ${colors.primary};
  border-bottom: solid 2px ${colors.primary};
`;

interface IProps {
  searchParams: ISearchParam;
  setSearchParams: (arg: ISearchParam) => void;
}

const SearchRegionArea = ({ searchParams, setSearchParams }: IProps) => {
  const hasIncludingAreaConds = useMemo(() => {
    return (
      (searchParams.addCond.area && searchParams.addCond.area.length > 0) ||
      (searchParams.addCond.country && searchParams.addCond.country.length > 0)
    );
  }, [searchParams.addCond.area, searchParams.addCond.country]);

  const setParamAbroad = (
    {
      key,
      value,
    }: {
      key: IAbroadSearch;
      value: ICirculars | IType | ILocation;
    },
    type: 'abroad' | 'local' = 'abroad',
  ) => {
    const abroadCond: any = searchParams[type];

    if (abroadCond[key]?.includes(value)) {
      abroadCond[key] = abroadCond[key].filter((item: string) => item !== value);
    } else {
      abroadCond[key].push(value);
    }
    setSearchParams({
      ...searchParams,
      ...abroadCond,
    });
  };

  return (
    <RegionBodyArea>
      <Conditions>
        <Flex $height='20px' $padding='0 8px'>
          <Text $width='100px'>CIRCULAR</Text>
          <CommonCheckboxLabel
            val={searchParams.abroad?.circulars?.includes('POSITION')}
            label='Position'
            doCheck={() => setParamAbroad({ key: 'circulars', value: 'POSITION' })}
          />
          <CommonCheckboxLabel
            val={searchParams.abroad?.circulars?.includes('ORDER')}
            doCheck={() => setParamAbroad({ key: 'circulars', value: 'ORDER' })}
            label='Order'
          />
        </Flex>
        <Spacing $val={'4px'} />
        <Flex $height='20px' $padding='0 8px'>
          <Text $width='100px'>대분류</Text>
          <CommonCheckboxLabel
            val={searchParams.abroad?.type?.includes('DIRECT')}
            doCheck={() => setParamAbroad({ key: 'type', value: 'DIRECT' })}
            label='Direct'
          />
          <CommonCheckboxLabel
            val={searchParams.abroad?.type?.includes('BROKERS')}
            doCheck={() => setParamAbroad({ key: 'type', value: 'BROKERS' })}
            label='Brokers'
          />
          <Padding $width='12px' />
          <CommonCheckboxLabel
            val={searchParams.abroad?.location?.includes('EAST')}
            doCheck={() => setParamAbroad({ key: 'location', value: 'EAST' })}
            label='East'
            disabled={hasIncludingAreaConds}
          />
          <CommonCheckboxLabel
            val={searchParams.abroad?.location?.includes('WEST')}
            doCheck={() => setParamAbroad({ key: 'location', value: 'WEST' })}
            label='West'
            disabled={hasIncludingAreaConds}
          />
          <CommonCheckboxLabel
            val={searchParams.abroad?.location?.includes('WW')}
            doCheck={() => setParamAbroad({ key: 'location', value: 'WW' })}
            label='WW'
            disabled={hasIncludingAreaConds}
          />
        </Flex>
      </Conditions>
      <Conditions>
        <Flex $height='40px' $padding='0 8px'>
          <Text $width='100px'>CIRCULAR</Text>
          <CommonCheckboxLabel
            val={searchParams.local?.circulars?.includes('A-GRADE')}
            doCheck={() => setParamAbroad({ key: 'circulars', value: 'A-GRADE' }, 'local')}
            label='A-Grade'
          />
          <CommonCheckboxLabel
            val={searchParams.local?.circulars?.includes('B-GRADE')}
            doCheck={() => setParamAbroad({ key: 'circulars', value: 'B-GRADE' }, 'local')}
            label='B-Grade'
          />
          <CommonCheckboxLabel
            val={searchParams.local?.circulars?.includes('C-GRADE')}
            doCheck={() => setParamAbroad({ key: 'circulars', value: 'C-GRADE' }, 'local')}
            label='C-Grade'
          />
          <CommonCheckboxLabel
            val={searchParams.local?.circulars?.includes('D-GRADE')}
            doCheck={() => setParamAbroad({ key: 'circulars', value: 'D-GRADE' }, 'local')}
            label='D-Grade'
          />
        </Flex>
      </Conditions>
    </RegionBodyArea>
  );
};

export default SearchRegionArea;
