import React, { useEffect, useState } from 'react';
import CommonInput from '../../components/CommonInput';
import CommonButton from '../../components/CommonButton';
import { Flex, Padding, Text } from '../../assets/styles';
import styled from 'styled-components';
import CommonModal from '../../components/CommonModal';

const Container = styled.div`
  height: fit-content;
  width: fit-content;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  margin: auto;
`;

interface IProps {
  showModal: boolean;
  width: string;
  msg: string;
  onConfirm: (arg: string) => void;
  closeModal: () => void;
  value: string;
}

const InputModal = ({ showModal, width, onConfirm, closeModal, msg, value }: IProps) => {
  const [val, setVal] = useState(value);
  useEffect(() => {
    if (showModal) setVal(value);
  }, [showModal]);

  return (
    <CommonModal showModal={showModal} closeModal={closeModal}>
      <Container>
        <Text $size='18px'>{msg}</Text>
        <Padding $val='12px' />
        <CommonInput width={width} value={val} changingValue={setVal} />
        <Padding $val='12px' />
        <Flex $justify='flex-end'>
          <CommonButton label='취소' onClick={closeModal} type='secondary' />
          <CommonButton label='확인' onClick={() => onConfirm(val)} />
        </Flex>
      </Container>
    </CommonModal>
  );
};

export default InputModal;

InputModal.defaultProps = {
  value: '',
};
