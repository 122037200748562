import ISearchParam, {
  IAbroadSearch,
  IAddSearch,
  IExcluSearch,
  ISearchKey,
} from '../../interfaces/ISearchParam';

export const setParams = (
  paramKey: ISearchKey,
  searchParams: ISearchParam,
  setSearchParams: (arg: ISearchParam) => void,
  {
    key,
    value,
  }: {
    key: IExcluSearch | IAddSearch | IAbroadSearch;
    value: string | string[] | null | undefined;
  },
  replace = false,
) => {
  const objCond: any = searchParams[paramKey];

  if (Array.isArray(value)) {
    objCond[key] = value;
  } else {
    if (replace) {
      if (typeof value === 'object') objCond[key] = value;
      else objCond[key] = [value];
    } else {
      if (objCond[key]?.includes(value)) {
        objCond[key] = objCond[key].filter((item: string) => item !== value);
      } else {
        objCond[key].push(value);
      }
    }
  }

  setSearchParams({
    ...searchParams,
    [paramKey]: { ...objCond },
  });
};
