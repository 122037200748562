import React, { ReactElement, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../assets/styles';
import { ISelectOption } from '../interfaces/fields';

const Container = styled.div``;

const SelectLabel = styled.div<{ $width: string }>`
  width: ${({ $width }) => $width};
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  padding-left: 8px;
  position: static;
  border-radius: 4px;
  border: solid 1px #dfe0e6;
  box-sizing: border-box;

  &:hover {
    border-color: ${colors.hoverBg};
    cursor: pointer;
  }
`;

const OptionContainer = styled.div<{ $width: string }>`
  width: ${({ $width }) => $width};
  position: absolute;
  border-radius: 4px;
  border: solid 1px #dfe0e6;
  background-color: #fff;
  box-sizing: border-box;
`;

const Option = styled.div`
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding-left: 8px;
  box-sizing: border-box;

  &:hover {
    background-color: ${colors.hoverBg};
    cursor: pointer;
  }
`;

interface SelectProps {
  width: string;
  options: ISelectOption[];
  selectOption: (arg0: string) => void;
  selectedOption: string | number;
}

const CommonSelect = ({
  width,
  options,
  selectOption,
  selectedOption,
}: SelectProps): ReactElement => {
  const [showOptions, setShowOptions] = useState(false);
  const selectedLabel = useMemo(() => {
    if (options.length <= 0 || !selectedOption) return '';
    return options.find(({ key }) => key === selectedOption)?.value;
  }, [selectedOption]);

  const clickOption = (opt: ISelectOption) => {
    setShowOptions(false);
    selectOption(opt.key);
  };

  return (
    <Container onMouseEnter={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)}>
      <SelectLabel $width={width}>{selectedLabel}</SelectLabel>
      {showOptions && (
        <OptionContainer $width={width}>
          {options.map((opt) => (
            <Option key={opt.key} onClick={() => clickOption(opt)}>
              {opt.value}
            </Option>
          ))}
        </OptionContainer>
      )}
    </Container>
  );
};

export default CommonSelect;
