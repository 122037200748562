import React, {
  ElementRef,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import PageContainer from '../components/PageContainer';
import CommonButton from '../components/CommonButton';
import SearchCondition from './search/SearchCondition';
import {
  delEmail,
  delSpecialized,
  getCountries,
  getEmailCodes,
  getEmails,
  postSpecialized,
  putSpecialized,
} from '../apis/emailService';
import EmailVO from '../interfaces/EmailVO';
import EmailTable from '../components/EmailTable';
import ISearchParam from '../interfaces/ISearchParam';
import ActionArea from './search/ActionArea';
import { useDispatch } from 'react-redux';
import { setCountryMeta } from '../redux/woorimActions';
import * as XLSX from 'xlsx';
import CopyEmailModal from './modal/CopyEmailModal';
import SpecializedModal from './modal/SpecializedModal';
import CommonInput from '../components/CommonInput';
import { toastInfo } from '../utils/toastUtil';
import EmailModifierModal, { initForm } from './modal/EmailModifierModal';
import ConfirmModal from './modal/ConfirmModal';
import { Spacing } from '../components/Common';
import AddSpecializedEmailModal from './modal/AddSpecializedEmailModal';
import { Padding } from '../assets/styles';

const SearchActions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 0;
`;

const EmailSearchView = (): ReactElement => {
  const dispatch = useDispatch();
  const searchCondRef = useRef<ElementRef<typeof SearchCondition>>(null);
  const emailTableRef = useRef<ElementRef<typeof EmailTable>>(null);
  const [emails, setEmails] = useState<EmailVO[]>([]);
  const [domains, setDomains] = useState<string[]>([]);
  const [emailSelectOptions, setEmailSelectOptions] = useState<string[]>([]);
  const [specializedList, setSpecializedList] = useState<string[]>([]);
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [showCopyEmailModal, setShowCopyEmailModal] = useState(false);
  const [showSpecializedEmailAddModal, setShowSpecializedEmailAddModal] = useState(false);
  const [editValue, setEditValue] = useState('');
  const [emailParam, setEmailParam] = useState('');
  const [companyParam, setCompanyParam] = useState('');
  const [sortType, setSortType] = useState({ sort: '', direction: '' });
  const [editRow, setEditRow] = useState({ ...initEditRow });
  const [showConfirm, setShowConfirm] = useState(false);
  const [specializedModal, setSpecializedModal] = useState<SpecializedModal>({
    msg: '',
    show: false,
    mode: 'ADD',
  });
  const [searchParams, setSearchParams] = useState<ISearchParam>(
    JSON.parse(JSON.stringify(initSearchParams)),
  );

  useEffect(() => {
    getCountries().then((countries) => {
      dispatch(setCountryMeta(countries));
    });
    getEmailCodes().then(({ domains, emails: emailSelectOptionList, specializedList }) => {
      setDomains(domains);
      setEmailSelectOptions(emailSelectOptionList);
      setSpecializedList(specializedList);
    });
    getEmails({ searchParams }).then(({ emails, totalCnt }) => {
      setEmails(emails);
      setTotalCnt(totalCnt);
    });
  }, []);

  const isDistinctEmails = useMemo(
    () =>
      searchParams.abroad.circulars.length > 0 ||
      searchParams.abroad.type.length > 0 ||
      searchParams.abroad.location.length > 0 ||
      searchParams.local.circulars.length > 0,

    [searchParams],
  );

  const doClear = () => {
    emailTableRef?.current?.resetCheckedRows();

    setSearchParams(JSON.parse(JSON.stringify(initSearchParams)));
    setEmailParam('');
    setCompanyParam('');
    searchCondRef?.current?.resetSelect();

    // getEmails({ searchParams: { ...initSearchParams, emailParam } }).then(
    //   ({ emails, totalCnt }) => {
    //     setEmails(emails);
    //     setTotalCnt(totalCnt);
    //   },
    // );
  };

  const fetchEmails = (sort?: string, direction?: string) => {
    getEmails({
      searchParams: { ...searchParams, emailParam, companyParam },
      sort,
      direction,
    }).then(({ emails, totalCnt }) => {
      setEmails(emails);
      setTotalCnt(totalCnt);
    });
  };

  const exportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(emails);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'emails');
    XLSX.writeFile(workbook, 'Emails.xlsx');
  };

  const doSpecializedConfirm = async (val: string, beforeVal: string) => {
    if (!emailTableRef?.current) return;
    const specialized = val.replace(/ /g, '').toUpperCase();
    const beforeSpecialized = beforeVal.replace(/ /g, '').toUpperCase();
    if (!specialized) closeModal();

    if (specializedModal.mode === 'ADD') {
      await postSpecialized(
        emailTableRef.current.getCheckedRows().map(({ id }) => id),
        specialized.trim(),
      );
    } else if (specializedModal.mode === 'DEL') {
      await delSpecialized(
        emailTableRef.current.getCheckedRows().map(({ id }) => id),
        specialized.trim(),
      );
    } else if (specializedModal.mode === 'EDIT') {
      await putSpecialized(
        emailTableRef.current.getCheckedRows().map(({ id }) => id),
        specialized.trim(),
        beforeSpecialized.trim(),
      );
      setEditValue('');
    }

    emailTableRef.current.resetCheckedRows();
    fetchEmails();
    getEmailCodes().then(({ specializedList }) => {
      setSpecializedList(specializedList);
    });
    closeModal();
  };
  const closeModal = () => {
    setSpecializedModal((prev) => ({
      ...prev,
      show: false,
    }));
  };

  const doSortEmails = (field: string) => {
    const direction = sortType.sort === field && sortType.direction === 'desc' ? 'asc' : 'desc';
    setSortType({ sort: field, direction });
    fetchEmails(field, direction);
  };

  const doEditEmail = (email: EmailVO) => {
    setEditRow((prev) => ({
      ...prev,
      email,
      show: true,
      mode: 'EDIT',
    }));
  };

  const doAddEmail = () => {
    setEditRow((prev) => ({
      ...prev,
      show: true,
      mode: 'ADD',
    }));
  };

  const doSearch = () => {
    emailTableRef?.current?.resetCheckedRows();
    fetchEmails();
  };

  const showSpecializedModal = (param: SpecializedModal) =>
    setSpecializedModal((prev) => ({ ...prev, ...param }));

  const doDelEmail = async () => {
    const ids = emailTableRef?.current?.getCheckedRows().map(({ id }) => id);
    if (ids) {
      await delEmail(ids);
      doRefresh();
    }
  };

  const sendPersonally = async () => {
    const ids = emailTableRef?.current?.getCheckedRows().map(({ id }) => id);
    let targetEmails: EmailVO[];
    if (ids && ids.length > 0) targetEmails = emails.filter(({ id }) => ids.includes(id));
    else targetEmails = emails;

    if (isDistinctEmails) {
      const distinctTargetEmails = new Map(
        targetEmails.map((em) => [em.email.toLowerCase(), em]),
      ).values() as unknown as EmailVO[];
      targetEmails = [...distinctTargetEmails];
    }

    const domainSet = new Map();
    targetEmails.forEach((email) => {
      if (!email.name || email.name.toUpperCase().includes('TEAM')) return;

      const domain = email.email.split('@')[1].toUpperCase();
      domainSet.set(
        domain,
        domainSet.has(domain)
          ? `${domainSet.get(domain)}-${email.name.split(' ')[0]}`
          : email.name.split(' ')[0],
      );
    });

    const nameEmail = targetEmails
      .filter(({ name }) => name)
      .map((email) => {
        const name = email.name.toUpperCase().includes('TEAM')
          ? domainSet.get(email.email.split('@')[1].toUpperCase()) || email.name
          : email.name.replaceAll(' ', '%20');
        return `%22${name}%22%3c${email.email}%3e`;
      })
      .join('; ');
    const forClipboard = targetEmails
      .filter(({ name }) => name)
      .map((email) => {
        const name = email.name.trim().toUpperCase().includes('TEAM')
          ? domainSet.get(email.email.split('@')[1].toUpperCase())
          : email.name;
        return `"${name}" <${email.email}>`;
      })
      .join('; ');

    await navigator.clipboard.writeText(forClipboard + '; "WRC"<dry@woorimship.kr>');
    toastInfo('복사되었습니다.');
    window.open(`mailto:${nameEmail}; '%22WRC%22%3cdry@woorimship.kr%3e'`);
  };

  const doRefresh = () => {
    getEmailCodes().then(({ specializedList }) => {
      setSpecializedList(specializedList);
    });
    doSearch();
  };

  const getCopyTargetEmails = useCallback(() => {
    const ids = emailTableRef?.current?.getCheckedRows().map(({ id }) => id);
    if (ids && ids.length > 0) {
      return emails.filter(({ id }) => ids.includes(id));
    }
    return emails;
  }, [emails]);

  const saveSpecializedEmails = async ({
    specialized,
    targetEmails,
  }: {
    specialized: string;
    targetEmails: number[];
  }) => {
    if (specialized) {
      await postSpecialized(targetEmails, specialized.trim());
      setShowSpecializedEmailAddModal(false);
    }
  };

  return (
    <>
      <PageContainer>
        <SearchCondition
          emails={emailSelectOptions}
          domains={domains}
          specializedList={specializedList}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          doSearch={doSearch}
          ref={searchCondRef}
        />
        <SearchActions>
          <div>
            <CommonInput
              width='100%'
              value={emailParam}
              placeholder={'email search'}
              changingValue={(val) => setEmailParam(val)}
              onEnter={doSearch}
            />
            <Padding $val={'4px'} />
            <CommonInput
              width='100%'
              value={companyParam}
              placeholder={'company search'}
              changingValue={(val) => setCompanyParam(val)}
              onEnter={doSearch}
            />
          </div>
          <Spacing $val={'4px'} />
          <CommonButton label='Search' size={'large'} onClick={doSearch} />
          <CommonButton
            label='Reset'
            size={'large'}
            type='secondary'
            color={'red'}
            onClick={doClear}
          />
          <ActionArea
            doSearch={() => {}}
            exportExcel={exportExcel}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            copyEmails={() => setShowCopyEmailModal(true)}
            sendPersonally={sendPersonally}
            addSpecializedEmails={() => setShowSpecializedEmailAddModal(true)}
            addSpecialized={() => {
              const checkedCnt = emailTableRef?.current?.getChecked().length || 0;
              if (checkedCnt > 0) {
                setEditValue('');
                showSpecializedModal({
                  mode: 'ADD',
                  msg: '추가 하려는 값을 입력하세요.',
                  show: true,
                });
              }
            }}
            editSpecialized={() => {
              const checkedCnt = emailTableRef?.current?.getChecked().length || 0;
              if (checkedCnt > 0) {
                setEditValue('');
                showSpecializedModal({
                  mode: 'EDIT',
                  msg: '수정 할 값을 입력해 주세요.',
                  show: true,
                });
              }
            }}
            delSpecialized={() => {
              const checkedCnt = emailTableRef?.current?.getChecked().length || 0;
              if (checkedCnt > 0) {
                setEditValue('');
                showSpecializedModal({
                  mode: 'DEL',
                  msg: '삭제 하려는 값을 입력하세요.',
                  show: true,
                });
              }
            }}
            delEmail={() => setShowConfirm(true)}
            addEmail={doAddEmail}
          />
        </SearchActions>
        <EmailTable
          emails={emails}
          totalCnt={totalCnt}
          sortEmails={doSortEmails}
          rowDoubleClick={doEditEmail}
          ref={emailTableRef}
        />
      </PageContainer>
      <CopyEmailModal
        showModal={showCopyEmailModal}
        isDistinct={isDistinctEmails}
        closeModal={() => setShowCopyEmailModal(false)}
        emails={getCopyTargetEmails().map(({ email }) => email)}
      />
      <SpecializedModal
        showModal={specializedModal.show}
        width={'250px'}
        msg={specializedModal.msg}
        value={editValue}
        mode={specializedModal.mode}
        options={specializedList}
        onConfirm={doSpecializedConfirm}
        closeModal={() => setSpecializedModal((prev) => ({ ...prev, show: false }))}
      />
      <EmailModifierModal
        mode={editRow.mode}
        showModal={editRow.show}
        email={editRow.email}
        refresh={doRefresh}
        closeModal={() => setEditRow((prev) => ({ ...prev, ...initEditRow, show: false }))}
      />
      <ConfirmModal
        showModal={showConfirm}
        closeModal={() => setShowConfirm(false)}
        msg={'삭제하시겠습니까?'}
        clickConfirm={doDelEmail}
      />
      <AddSpecializedEmailModal
        showModal={showSpecializedEmailAddModal}
        closeModal={() => setShowSpecializedEmailAddModal(false)}
        bespoke={searchParams.specialized.join(',')}
        saveSpecialized={saveSpecializedEmails}
      />
    </>
  );
};

export default EmailSearchView;

interface SpecializedModal {
  show: boolean;
  msg: string;
  mode: 'ADD' | 'EDIT' | 'DEL';
}

export const initSearchParams = {
  local: {
    circulars: [],
  },
  abroad: {
    circulars: [],
    type: [],
    location: [],
  },
  addCond: {
    area: [],
    country: [],
    email: [],
  },
  excluCond: {
    area: [],
    country: [],
    domain: [],
    style: [],
    specialized: [],
    excludeEmails: [],
  },
  specialized: [],
  specializedExc: [],
  emailParam: '',
  excludeEmails: '',
};

const initEditRow: EditRow = {
  email: {
    ...initForm,
  },
  show: false,
  mode: 'ADD',
};

interface EditRow {
  email: EmailVO;
  show: boolean;
  mode: 'ADD' | 'EDIT';
}
