import { createReducer } from '@reduxjs/toolkit';
import { setCountryMeta, setFieldMeta } from './woorimActions';
import { IWoorimInitState } from '../interfaces/common';

const initState: IWoorimInitState = {
  countries: [],
  fieldMeta: [
    { name: 'name', type: 'SELECT', options: 'EAST|WEST' },
    { name: 'email', type: 'SELECT', options: 'Direct|Brokers|Local' },
    { name: 'company', type: 'AUTO' },
    { name: 'country', type: 'AUTO' },
    { name: 'type', type: 'AUTO' },
    { name: 'circulars', type: 'AUTO' },
    { name: 'location', type: 'AUTO' },
    { name: 'area', type: 'AUTO' },
  ],
};

const woorimReducers = createReducer(initState, {
  [setFieldMeta.type]: (state, action) => {
    state.fieldMeta = action.payload;
  },
  [setCountryMeta.type]: (state, action) => {
    state.countries = action.payload;
  },
});

export default woorimReducers;
