import React, { useEffect, useState } from 'react';
import PageContainer from '../../components/PageContainer';
import EmailTable from '../../components/EmailTable';
import EmailVO from '../../interfaces/EmailVO';
import { getRecentEmails } from '../../apis/emailService';

const EmailListView = () => {
  const [emails, setEmails] = useState<EmailVO[]>([]);
  const [totalCnt, setTotalCnt] = useState(0);

  useEffect(() => {
    getRecentEmails().then(({ totalCnt, emails }) => {
      setEmails(emails);
      setTotalCnt(totalCnt);
    });
  }, []);

  const doNothing = () => {};

  return (
    <>
      <PageContainer>
        <EmailTable
          emails={emails}
          totalCnt={totalCnt}
          sortEmails={doNothing}
          rowDoubleClick={doNothing}
          showAudit
        />
      </PageContainer>
    </>
  );
};

export default EmailListView;
