import React, { useEffect, useMemo, useState } from 'react';
import CommonModal from '../../components/CommonModal';
import styled from '@emotion/styled';
import { Flex, FlexColumn, Text } from '../../assets/styles';
import CommonButton from '../../components/CommonButton';
import { Spacing } from '../../components/Common';
import chunk from 'lodash/chunk';
import CommonInput from '../../components/CommonInput';
import { toastInfo } from '../../utils/toastUtil';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  emails: string[];
  isDistinct?: boolean;
}

const defaultPer = 500;

const CopyEmailModal = ({ showModal, closeModal, emails, isDistinct }: Props) => {
  const [perEmails, setPerEmails] = useState<number>(defaultPer);
  const [copiedGroup, setCopiedGroup] = useState<number[]>([]);
  const emailList = useMemo(() => {
    const list = isDistinct
      ? emails.reduce((prev, curr) => {
          if (prev.includes(curr.toLowerCase())) return prev;
          else return [...prev, curr.toLowerCase()];
        }, [] as string[])
      : emails;

    return [...list, 'dry@woorimship.kr'];
  }, [emails]);
  const emailGroups = useMemo(() => {
    return chunk(emailList, perEmails);
  }, [perEmails, emailList]);

  useEffect(() => {
    if (showModal) {
      setPerEmails(defaultPer);
      setCopiedGroup([]);
    }
  }, [showModal]);

  const copyEmails = async ({ idx, emailGroup }: { idx: number; emailGroup: string[] }) => {
    setCopiedGroup((prev) => [...prev, idx]);

    await navigator.clipboard.writeText(emailGroup.join(';'));
    toastInfo('복사되었습니다.');
    window.open(`mailto:?bcc=${emailGroup.join(';')}`);
  };

  const changePer = (val: string) => {
    setPerEmails(Number(val));
    setCopiedGroup([]);
  };

  return (
    <CommonModal showModal={showModal} closeModal={closeModal}>
      <Container>
        <CommonInput width={'100px'} value={perEmails} changingValue={changePer} />
        {emailGroups.map((emailGroup, idx) => (
          <EmailGroup $alignItems={'flex-end'} key={`emailGroups-${idx}`}>
            <EmailsArea>{emailGroup.join(', ')}</EmailsArea>
            <Spacing $val={'4px'} />
            <Flex>
              {copiedGroup.includes(idx) && <Text>Done</Text>}
              <Spacing $val={'4px'} />
              <Text>{`${emailGroup.length}/${emailList.length}`}</Text>
              <Spacing $val={'4px'} />
              <CommonButton
                type={'secondary'}
                label={'Copy'}
                onClick={() => copyEmails({ idx, emailGroup })}
              />
            </Flex>
          </EmailGroup>
        ))}
      </Container>
    </CommonModal>
  );
};

export default CopyEmailModal;

const Container = styled.div`
  width: 500px;
  height: 500px;
`;

const EmailsArea = styled(Text)`
  width: 100%;
  max-height: 100px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
`;

const EmailGroup = styled(FlexColumn)`
  padding: 8px 0;
  border-bottom: solid 1px lightgrey;
`;
