import React, { ReactNode } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#modal');
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

interface IProps {
  showModal: boolean;
  closeModal: () => void;
  children: ReactNode;
}

const CommonModal = ({ showModal, closeModal, children }: IProps) => {
  return (
    <Modal isOpen={showModal} style={customStyles} onRequestClose={closeModal} shouldCloseOnEsc>
      {children}
    </Modal>
  );
};

export default CommonModal;
