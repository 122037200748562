import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../assets/styles';
import { IButtonType } from '../interfaces/common';

type btnType = 'primary' | 'secondary';
type btnSize = 'large' | 'medium';

const Button = styled.button<{ btnType: btnType; size: btnSize; color?: string }>`
  border: solid 1px lightgrey;
  border-radius: 4px;

  ${({ btnType }) => {
    if (btnType === 'primary') {
      return css`
        color: #fff;
        background-color: ${colors.primary};
      `;
    } else if (btnType === 'secondary') {
      return css`
        color: ${colors.primary};
        background-color: #fff;
      `;
    }
  }}
  ${({ size }) => {
    if (size === 'medium') {
      return css`
        height: 30px;
        min-width: 60px;
        padding: 0 8px;
        font-size: 14px;
      `;
    } else if (size === 'large') {
      return css`
        height: 40px;
        min-width: 60px;
        padding: 0 12px;
        font-size: 20px;
      `;
    }
  }}
  ${({ color }) => css`
    color: ${color};
  `}
  &:not(:last-child) {
    margin-right: 4px;
  }

  &:hover {
    cursor: pointer;
    color: #fff;
    background-color: ${colors.hoverBg};
  }

  &:disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
`;

interface ButtonProps {
  label: string | number;
  onClick: () => void;
  type?: IButtonType;
  disabled?: boolean;
  size?: btnSize;
  color?: string;
}

const CommonButton = ({
  label,
  onClick,
  type = 'primary',
  disabled = false,
  size = 'medium',
  color,
}: ButtonProps) => {
  return (
    <Button onClick={onClick} btnType={type} disabled={disabled} size={size} color={color}>
      {label}
    </Button>
  );
};

export default CommonButton;
