import React, { useEffect, useState } from 'react';
import CommonInput from '../../components/CommonInput';
import CommonButton from '../../components/CommonButton';
import { Flex, Padding, Text } from '../../assets/styles';
import styled from 'styled-components';
import CommonModal from '../../components/CommonModal';
import CommonSelect2 from '../../components/CommonSelect2';

const Container = styled.div`
  height: fit-content;
  width: fit-content;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  margin: auto;
`;

interface IProps {
  showModal: boolean;
  width: string;
  msg: string;
  onConfirm: (arg: string, arg2: string) => void;
  closeModal: () => void;
  value: string;
  mode: string;
  options: string[];
}

const SpecializedModal = ({
  showModal,
  width,
  onConfirm,
  closeModal,
  msg,
  value,
  mode,
  options,
}: IProps) => {
  const [val, setVal] = useState(value);
  const [beforeVal, setBeforeVal] = useState(value);
  useEffect(() => {
    if (showModal) {
      setVal(value);
      setBeforeVal('');
    }
  }, [showModal]);

  return (
    <CommonModal showModal={showModal} closeModal={closeModal}>
      <Container>
        <Text $size='18px'>{msg}</Text>
        <Padding $val='12px' />
        {mode === 'EDIT' ? (
          <>
            <CommonInput
              width={width}
              value={beforeVal}
              changingValue={setBeforeVal}
              placeholder={'before value(only one)'}
            />
            <Padding $val='8px' />
            <CommonInput
              width={width}
              value={val}
              changingValue={setVal}
              placeholder={'after value'}
            />
          </>
        ) : (
          <CommonSelect2
            freeSolo
            multiple={false}
            showLabel={false}
            selectedVal={val}
            selCountry={(v) => setVal(v as string)}
            options={options}
          />
        )}
        <Padding $val='12px' />
        <Flex $justify='flex-end'>
          <CommonButton label='취소' onClick={closeModal} type='secondary' />
          <CommonButton label='확인' onClick={() => onConfirm(val, beforeVal)} />
        </Flex>
      </Container>
    </CommonModal>
  );
};

export default SpecializedModal;

SpecializedModal.defaultProps = {
  value: '',
};
