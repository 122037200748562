import HTTP from '../apis/axiosInstance';
import ISearchParam from '../interfaces/ISearchParam';
import { trackPromise } from 'react-promise-tracker';
import EmailVO from '../interfaces/EmailVO';
import SearchConditionVO from '../interfaces/SearchConditionVO';

const getEmails = async ({
  searchParams,
  sort = 'email',
  direction = 'asc',
}: {
  searchParams?: ISearchParam;
  sort?: string;
  direction?: string;
}) => {
  const {
    data: { results },
  } = await trackPromise(
    HTTP.post(`/v1/emails/?sort=${sort}&direction=${direction}`, searchParams),
  );

  return results;
};

const getRecentEmails = async () => {
  const {
    data: { results },
  } = await trackPromise(HTTP.get('/v1/recent-emails'));

  return results;
};

const getEmail = async (id: number): Promise<EmailVO> => {
  const {
    data: { results },
  } = await trackPromise(HTTP.get(`v1/emails/${id}`));

  return results;
};

const getEmailCodes = async () => {
  const {
    data: {
      results: { domains = [], specializedList = [], emails = [] },
    },
  } = await trackPromise(HTTP.get('v1/email-codes'));

  return { domains, specializedList, emails };
};

const getCountries = async () => {
  const {
    data: { results },
  } = await trackPromise(HTTP.get('v1/countries'));

  return results || [];
};

const getSavedCondition = async (): Promise<SearchConditionVO[]> => {
  const {
    data: { results },
  } = await trackPromise(HTTP.get('/v1/search-condition'));

  return results.sort((a: SearchConditionVO, b: SearchConditionVO) => (a.name < b.name ? -1 : 1));
};

const postSaveCondition = async (name: string, data: ISearchParam) => {
  await trackPromise(HTTP.post('/v1/search-condition', { name, data }));
};

const putSaveCondition = async (id: number, data: ISearchParam) => {
  await trackPromise(HTTP.put('/v1/search-condition', { id, data }));
};

const delSavedCondition = async (id: number) => {
  await trackPromise(HTTP.delete(`/v1/search-condition/${id}`));
};

const postSpecialized = async (ids: number[], specialized: string) => {
  await trackPromise(HTTP.post('/v1/email/specialized', { ids, specialized }));
};

const delSpecialized = async (ids: number[], specialized: string) => {
  await trackPromise(HTTP.delete('/v1/email/specialized', { data: { ids, specialized } }));
};

const putSpecialized = async (id: number[], specialized: string, beforeSpecialized: string) => {
  await trackPromise(HTTP.put('/v1/email/specialized', { id, specialized, beforeSpecialized }));
};

const postEmail = async (email: EmailVO) => {
  await trackPromise(HTTP.post('/v1/email', { email }));
};

const putEmail = async (email: EmailVO) => {
  await trackPromise(HTTP.put('/v1/email', { email }));
};

const delEmail = async (ids: number[]) => {
  await trackPromise(HTTP.delete('/v1/email', { data: { ids } }));
};

export {
  getEmails,
  getRecentEmails,
  getEmail,
  getEmailCodes,
  getSavedCondition,
  postSaveCondition,
  delSavedCondition,
  getCountries,
  postSpecialized,
  delSpecialized,
  putSpecialized,
  postEmail,
  putEmail,
  delEmail,
  putSaveCondition,
};
