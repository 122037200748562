import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 1450px;
  min-width: 1580px;
  padding: 20px 40px 100px 40px;
`;

interface IProps {
  children: ReactNode;
  title?: string;
}

const PageContainer = ({ children }: IProps) => {
  return <Container>{children}</Container>;
};

export default PageContainer;
