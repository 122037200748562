import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Text } from '../assets/styles';

interface IProps {
  selectedVal: string[] | string;
  selCountry: (arg: string[] | string | null) => void;
  multiple?: boolean;
  showLabel?: boolean;
  freeSolo?: boolean;
  options: string[];
  disabled?: boolean;
}

const SelectCountry = ({
  selectedVal,
  options,
  selCountry,
  multiple = true,
  showLabel = true,
  freeSolo = false,
  disabled,
}: IProps) => {
  const doInputChange = (e: any) => {
    if (freeSolo) {
      selCountry(e?.target?.value || '');
    }
  };

  return (
    <>
      {showLabel && <Text $margin='0 8px 0 12px'>Country</Text>}
      <Autocomplete
        disabled={disabled}
        freeSolo={freeSolo}
        disablePortal
        multiple={multiple}
        value={selectedVal}
        id='combo-box-demo'
        options={options}
        sx={{ width: 250 }}
        onChange={(e, newVal) => {
          selCountry(newVal);
        }}
        onInputChange={doInputChange}
        renderInput={(params) => <TextField {...params} size='small' />}
      />
    </>
  );
};

export default SelectCountry;
