import { trackPromise } from 'react-promise-tracker';
import HTTP from './axiosInstance';
import { ISignup } from '../interfaces/common';

const postLogin = async (data: ISignup) => {
  await trackPromise(HTTP.post('/v1/login', data));
};
const postLogout = async () => {
  await trackPromise(HTTP.post('/v1/logout'));
};
const postNotice = async (data: string) => {
  await trackPromise(HTTP.post('/v1/notice', data));
};
const getNotice = async (): Promise<string> => {
  const {
    data: {
      results: { notice },
    },
  } = await trackPromise(HTTP.get('/v1/notice'));

  return notice;
};

export { postLogin, postLogout, postNotice, getNotice };
