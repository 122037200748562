import React, { forwardRef, useImperativeHandle, useState } from 'react';
import styled, { css } from 'styled-components';
import EmailVO from '../interfaces/EmailVO';
import CommonCheckbox from './CommonCheckbox';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useSelector } from 'react-redux';
import { IWoorimInitState } from '../interfaces/common';
import { format } from 'date-fns';

const TableContainer = styled.div`
  width: fit-content;
  border-top: solid 1px lightgrey;
  border-left: solid 1px lightgrey;
`;

const HeaderRow = styled.div`
  display: flex;
`;

const Header = styled.div<{ $width: string; $sortable?: boolean }>`
  width: ${({ $width }) => $width};
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-right: solid 1px lightgrey;
  border-bottom: solid 1px lightgrey;
  background-color: #dfdfdf;

  ${({ $sortable }) =>
    $sortable &&
    css`
      cursor: pointer;
    `}
`;

const Bodies = styled.div`
  height: 500px;
  overflow-y: auto;
`;

const BodyRow = styled.div<{ $checked: boolean }>`
  display: flex;
  background-color: ${({ $checked }) => ($checked ? '#F5F5F5' : undefined)};

  &:hover {
    cursor: pointer;
    background-color: #f8f8ff;
  }
`;

const Body = styled.div<{ $width: string; $textAlign?: string }>`
  width: ${({ $width }) => $width};
  height: 28px;
  line-height: 28px;
  text-align: ${({ $textAlign }) => $textAlign || 'center'};
  font-size: 14px;
  border-right: solid 1px lightgrey;
  border-bottom: solid 1px lightgrey;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px;
  white-space: pre;
`;

interface IProps {
  emails: EmailVO[];
  totalCnt: number;
  sortEmails: (arg: string) => void;
  rowDoubleClick: (arg: EmailVO) => void;
  checkable?: boolean;
  showAudit?: boolean;
}

interface refImperative {
  getChecked: () => number[];
  getCheckedRows: () => EmailVO[];
  resetCheckedRows: () => void;
}

const EmailTable = forwardRef<refImperative, IProps>(
  ({ checkable, emails, totalCnt, sortEmails, rowDoubleClick, showAudit }, ref) => {
    const [checkAll, setCheckAll] = useState(false);
    const [checkedRows, setCheckedRows] = useState<number[]>([]);
    const countryMeta = useSelector((state: IWoorimInitState) => state.countries);
    const checkRow = (idx: number) => {
      let newChecks;
      if (checkedRows.includes(idx)) newChecks = checkedRows.filter((val) => val !== idx);
      else newChecks = [...checkedRows, idx];

      if (newChecks.length !== emails.length) setCheckAll(false);
      else setCheckAll(true);

      setCheckedRows(newChecks);
    };

    useImperativeHandle(ref, () => ({
      getChecked: () => checkedRows,
      getCheckedRows: () => checkedRows.map((idx) => emails[idx]),
      resetCheckedRows: () => {
        setCheckAll(false);
        setCheckedRows([]);
      },
    }));

    const doCheckAll = () => {
      if (checkAll) setCheckedRows([]);
      else setCheckedRows(emails.map((_, idx) => idx));

      setCheckAll(!checkAll);
    };

    const getCountry = (countryCode: string) => {
      return countryMeta.find(({ code }) => code === countryCode)?.country;
    };

    return (
      <>
        <div>
          {emails.length} / {totalCnt}
        </div>
        <TableContainer>
          <HeaderRow>
            {checkable && (
              <Header $width='50px'>
                <CommonCheckbox val={checkAll} doCheck={doCheckAll} />
              </Header>
            )}
            <Header $width='150px' $sortable onClick={() => sortEmails('name')}>
              NAME
            </Header>
            <Header $width='300px' $sortable onClick={() => sortEmails('email')}>
              E-MAIL
            </Header>
            <Header $width='160px' $sortable onClick={() => sortEmails('company')}>
              COMPANY
            </Header>
            <Header $width='100px' $sortable onClick={() => sortEmails('country')}>
              COUNTRY
            </Header>
            <Header $width='100px' $sortable onClick={() => sortEmails('type')}>
              TYPE
            </Header>
            <Header $width='100px' $sortable onClick={() => sortEmails('circulars')}>
              CIRCULARS
            </Header>
            <Header $width='100px' $sortable onClick={() => sortEmails('location')}>
              LOCATION
            </Header>
            <Header $width='300px'>BESPOKE</Header>
            <Header $width='150px'>REMARK</Header>
            {showAudit && (
              <>
                <Header $width='100px'>CRE_BY</Header>
                <Header $width='100px'>CRE_AT</Header>
                <Header $width='100px'>UPD_BY</Header>
                <Header $width='100px'>UPD_AT</Header>
              </>
            )}
          </HeaderRow>
          <Bodies>
            {emails.map((email, idx) => (
              <BodyRow
                key={idx}
                $checked={checkedRows.includes(idx)}
                onDoubleClick={() => rowDoubleClick(email)}
              >
                {checkable && (
                  <Body $width='50px'>
                    <CommonCheckbox val={checkedRows.includes(idx)} doCheck={() => checkRow(idx)} />
                  </Body>
                )}
                <Body $width='150px'>{email.name}</Body>
                <Body $width='300px'>{email.email}</Body>
                <Body $width='160px'>{email.company}</Body>
                <Tippy content={getCountry(email.country)}>
                  <Body $width='100px'>{email.country}</Body>
                </Tippy>
                <Body $width='100px'>{email.type}</Body>
                <Body $width='100px'>{email.circulars}</Body>
                <Body $width='100px'>{email.location}</Body>
                <Tippy content={email.specialized}>
                  <Body $width='300px' $textAlign={'start'}>
                    {email.specialized}
                  </Body>
                </Tippy>
                <Body $width='150px' $textAlign={'start'}>
                  {email.remark}
                </Body>
                {showAudit && (
                  <>
                    <Body $width='100px'>{email.created_by || ''}</Body>
                    <Body $width='100px'>
                      {email.created_at ? format(new Date(email.created_at), 'MM/dd/yy') : ''}
                    </Body>
                    <Body $width='100px'>{email.updated_by || ''}</Body>
                    <Body $width='100px'>
                      {email.updated_at ? format(new Date(email.updated_at), 'MM/dd/yy') : ''}
                    </Body>
                  </>
                )}
              </BodyRow>
            ))}
          </Bodies>
        </TableContainer>
      </>
    );
  },
);

export default EmailTable;
EmailTable.displayName = 'EmailTable';

EmailTable.defaultProps = {
  checkable: true,
};
