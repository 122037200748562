import React, { useState } from 'react';
import CommonModal from '../../components/CommonModal';
import styled from 'styled-components';
import { Text, FlexColumn, Flex } from '../../assets/styles';
import CommonButton from '../../components/CommonButton';

interface Props {
  msg: string;
  showModal: boolean;
  closeModal: () => void;
  clickConfirm: () => void;
}

const ConfirmModal = ({ showModal, closeModal, msg, clickConfirm }: Props) => {
  const doConfirm = () => {
    clickConfirm();
    closeModal();
  };

  return (
    <CommonModal showModal={showModal} closeModal={closeModal}>
      <Container>
        <FlexColumn $justify={'space-between'} $height={'100%'}>
          <Text>{msg}</Text>
          <Flex>
            <CommonButton label={'NO'} onClick={closeModal} type={'secondary'} />
            <CommonButton label={'YES'} onClick={doConfirm} />
          </Flex>
        </FlexColumn>
      </Container>
    </CommonModal>
  );
};

export default ConfirmModal;

const Container = styled.div`
  width: 200px;
  height: 100px;
`;
