import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { IUser, IWoorimInitState } from '../interfaces/common';
import PageContainer from '../components/PageContainer';
import CommonCheckbox from '../components/CommonCheckbox';
import CommonButton from '../components/CommonButton';
import { colors } from '../assets/styles';
import EmailModifierModal from './modal/EmailModifierModal';

const TableHeaderRow = styled.div`
  display: flex;
  border-top: solid 1px lightgrey;
  border-bottom: solid 1px lightgrey;
`;

const TableHeaderCell = styled.div<{ $width: string }>`
  width: ${({ $width }) => $width};
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 0 4px;
  border-left: solid 1px lightgrey;
`;

const TableRowCell = styled.div<{ $width: string }>`
  width: ${({ $width }) => $width};
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 0 4px;
  border-left: solid 1px lightgrey;
`;

const TableBodyRow = styled.div<{ $selected?: boolean }>`
  display: flex;
  background-color: ${({ $selected }) => ($selected ? colors.selectedBg : '#fff')};

  &:hover {
    cursor: pointer;
    background-color: ${colors.hoverBg};
  }
`;

const ActionGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
`;

interface IBodyRow<T> {
  rowData: T[];
}

interface ITableMeta {
  label: string;
  width: string;
  key: string;
}

const EmailView = () => {
  const [checkAll, setCheckAll] = useState(false);
  const [emailModifierMode, setEmailModifierMode] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const fieldMeta = useSelector((state: IWoorimInitState) => state.fieldMeta);
  const [users, setUsers] = useState<IUser[]>([
    {
      id: 0,
      name: 'SY1',
      email: 'test1@test.com',
      custom1: 'East',
      custom2: 'Direct',
      checked: false,
    },
    {
      id: 1,
      name: 'SY2',
      email: 'test2@test.com',
      custom1: 'West',
      custom2: 'Brokers',
      checked: false,
    },
    {
      id: 2,
      name: 'SY3',
      email: 'test3@test.com',
      custom1: 'East',
      custom2: 'Brokers',
      checked: false,
    },
    {
      id: 3,
      name: 'SY4',
      email: 'test4@test.com',
      custom1: 'East',
      custom2: 'Direct',
      checked: false,
    },
    {
      id: 4,
      name: 'SY5',
      email: 'test5@test.com',
      custom1: 'East',
      custom2: 'Local',
      checked: false,
    },
  ]);
  const tableMeta = useMemo<ITableMeta[]>(() => {
    return [
      { label: 'name', width: '100px', key: 'name' },
      { label: 'email', width: '250px', key: 'email' },
      { label: fieldMeta[0].name, width: '100px', key: 'custom1' },
      { label: fieldMeta[1].name, width: '100px', key: 'custom2' },
      { label: fieldMeta[2].name, width: '100px', key: 'custom3' },
      { label: fieldMeta[3].name, width: '100px', key: 'custom4' },
      { label: fieldMeta[4].name, width: '100px', key: 'custom5' },
    ];
  }, []);

  const selectedIds = useMemo<number[]>(() => {
    return users.filter(({ checked }) => checked).map(({ id }) => id);
  }, [users]);

  const selectedUsers = useMemo<IUser[]>(() => {
    return users.filter(({ checked }) => checked);
  }, [users]);

  const doCheckAll = () => {
    setCheckAll(!checkAll);
  };

  const doCheck = (idx: number) => {
    setUsers(
      users.map((user, uidx) => {
        if (idx === uidx) user.checked = !user.checked;
        return user;
      }),
    );
  };

  const doAdd = () => {
    setEmailModifierMode('add');
    setShowEditModal(true);
  };

  const doEdit = () => {
    setEmailModifierMode('edit');
    setShowEditModal(true);
  };

  const doDelete = () => {
    setUsers(users.filter(({ id }) => !selectedIds.includes(id)));
  };

  const BodyRow = <T extends { checked?: boolean }>({
    rowData,
    tableMeta,
    doCheck,
  }: {
    rowData: T;
    tableMeta: ITableMeta[];
    doCheck?: () => void;
  }) => {
    const getValue = (key: string): string | number | undefined => {
      if (rowData && typeof rowData === 'object' && key in rowData) {
        const castedRow = rowData as unknown as { [index: string]: string };
        return castedRow[key];
      }
      return undefined;
    };

    const clickRow = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (doCheck) doCheck();
    };

    return (
      <TableBodyRow onClick={(e) => clickRow(e)} $selected={rowData.checked}>
        <TableRowCell $width='30px'>
          <CommonCheckbox doCheck={doCheck} val={rowData.checked} />
        </TableRowCell>
        {tableMeta.map((field, idx) => (
          <TableRowCell $width={field.width} key={`${field.label}-${idx}`}>
            {getValue(field.key)}
          </TableRowCell>
        ))}
      </TableBodyRow>
    );
  };

  return (
    <PageContainer title='Emails'>
      <ActionGroup>
        <CommonButton label='DEL' onClick={doDelete} type='secondary' />
        <CommonButton label='EDIT' onClick={doEdit} type='secondary' />
        <CommonButton label='ADD' onClick={doAdd} />
      </ActionGroup>
      <TableHeaderRow>
        <TableHeaderCell $width='30px'>
          <CommonCheckbox val={checkAll} doCheck={doCheckAll} />
        </TableHeaderCell>
        {tableMeta.map((field, idx) => (
          <TableHeaderCell key={`${field.label}-${idx}`} $width={field.width}>
            {field.label}
          </TableHeaderCell>
        ))}
      </TableHeaderRow>
      {users.map((user, idx) => (
        <BodyRow
          rowData={user}
          tableMeta={tableMeta}
          key={user.email}
          doCheck={() => doCheck(idx)}
        />
      ))}
    </PageContainer>
  );
};

export default EmailView;
