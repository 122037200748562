import React, { forwardRef, SyntheticEvent, useEffect, useImperativeHandle, useState } from 'react';
import { Flex, FlexColumn, Padding, Text } from '../../assets/styles';
import ISearchParam from '../../interfaces/ISearchParam';
import styled from 'styled-components';
import { Autocomplete, TextField } from '@mui/material';
import CommonCheckboxLabel from '../../components/CommonCheckboxLabel';
import SearchConditionVO from '../../interfaces/SearchConditionVO';
import CommonButton from '../../components/CommonButton';
import Delete from '@mui/icons-material/Delete';
import {
  delSavedCondition,
  getSavedCondition,
  postSaveCondition,
  putSaveCondition,
} from '../../apis/emailService';
import ConfirmModal from '../modal/ConfirmModal';
import { initConfirm } from '../../const/common';
import InputModal from '../modal/InputModal';
import { toast } from 'react-toastify';
import { initSearchParams } from '../EmailSearchView';

interface IProps {
  specializedList: string[];
  searchParams: ISearchParam;
  setSearchParams: (arg: ISearchParam) => void;
  doSearch: () => void;
}

interface refImperative {
  resetSelect: () => void;
}

const SearchSpecialCondArea = forwardRef<refImperative, IProps>(
  ({ specializedList, searchParams, setSearchParams, doSearch }, ref) => {
    const [conditions, setConditions] = useState<SearchConditionVO[]>([]);
    const [preset, setPreset] = useState<SearchConditionVO | null>(null);
    const [confirm, setConfirm] = useState({ ...initConfirm });
    const [showNameModal, setShowNameModal] = useState(false);

    useEffect(() => {
      getSavedCondition().then((conditions) => setConditions(conditions));
    }, []);

    useImperativeHandle(ref, () => ({
      resetSelect: () => {
        setPreset(null);
      },
    }));

    const setParamSpecialized = (key: 'specialized' | 'specializedExc', val: string) => {
      let tmpParamSpecialized = searchParams[key];
      if (tmpParamSpecialized?.includes(val)) {
        tmpParamSpecialized = tmpParamSpecialized.filter((item: string) => item !== val);
      } else {
        tmpParamSpecialized?.push(val);
      }

      setSearchParams({
        ...searchParams,
        [key]: tmpParamSpecialized,
      });
    };

    const clickSaveCondition = async (name: string) => {
      if (!name) {
        toast('이름을 입력해 주세요', { type: 'error', position: 'bottom-center' });
      }

      await postSaveCondition(name, searchParams);
      const conditions = await getSavedCondition();

      setShowNameModal(false);
      setConditions(conditions);
    };

    const delCondition = async (id: number) => {
      setConfirm({
        show: true,
        msg: '삭제하시겠습니까?',
        doConfirm: async () => {
          await delSavedCondition(id);
          const conditions = await getSavedCondition();
          setConditions(conditions);
        },
      });
    };

    const putCondition = async (id: number) => {
      setConfirm({
        show: true,
        msg: '수정하시겠습니까?',
        doConfirm: async () => {
          await putSaveCondition(id, searchParams);
          const conditions = await getSavedCondition();
          setConditions(conditions);
        },
      });
    };

    return (
      <>
        <Flex $alignItems={'unset'} $borderBottom={'solid 1px #e2e2e2'}>
          <Text $margin='0 5px 0 0' $width='90px' $backcolor={'lightgray'} $center>
            BESPOKE
          </Text>
          <Padding $width='20px' />
          <FlexColumn $padding={'12px 0'} $alignItems={'flex-start'}>
            <Autocomplete
              disablePortal
              multiple
              value={searchParams.specialized}
              sx={{ width: 500 }}
              renderInput={(params) => <TextField {...params} size='small' />}
              options={specializedList}
              onChange={(e, newVal) => {
                setSearchParams({
                  ...searchParams,
                  specialized: newVal,
                });
              }}
            />
            <Flex $margin='8px 0 0 0'>
              <Text $margin='0 8px 0 0'>EXCLUDING</Text>
              <CommonCheckboxLabel
                val={searchParams?.specializedExc?.includes('DIRECT')}
                doCheck={() => setParamSpecialized('specializedExc', 'DIRECT')}
                label='Direct'
              />
              <CommonCheckboxLabel
                val={searchParams.specializedExc?.includes('BROKERS')}
                doCheck={() => setParamSpecialized('specializedExc', 'BROKERS')}
                label='Brokers'
              />
              <CommonCheckboxLabel
                val={searchParams.specializedExc?.includes('LOCALS')}
                doCheck={() => setParamSpecialized('specializedExc', 'LOCALS')}
                label='Locals'
              />
              <CommonCheckboxLabel
                val={searchParams.specializedExc?.includes('EAST')}
                doCheck={() => setParamSpecialized('specializedExc', 'EAST')}
                label='East'
              />
              <CommonCheckboxLabel
                val={searchParams.specializedExc?.includes('WEST')}
                doCheck={() => setParamSpecialized('specializedExc', 'WEST')}
                label='West'
              />
              <CommonCheckboxLabel
                val={searchParams.specializedExc?.includes('WW')}
                doCheck={() => setParamSpecialized('specializedExc', 'WW')}
                label='WW'
              />
            </Flex>
          </FlexColumn>
          <Padding $width='20px' />
          <Text $margin='0 5px 0 0' $width='90px' $backcolor={'lightgray'} $center>
            PRESET
          </Text>
          <Flex>
            <Padding $width='18px' />
            <Autocomplete
              sx={{ width: '300px' }}
              getOptionLabel={(opt: SearchConditionVO) => opt.name}
              value={preset}
              onChange={(e: SyntheticEvent, newVal: SearchConditionVO | null) => {
                setPreset(newVal);
                if (newVal) {
                  setSearchParams({
                    ...initSearchParams,
                    ...JSON.parse(newVal?.condition),
                  });
                  // setTimeout(() => {
                  //   doSearch();
                  // }, 200);
                }
              }}
              renderInput={(params) => <TextField {...params} size='small' />}
              renderOption={(val, option: SearchConditionVO) => {
                return (
                  <Flex $justify='space-between' key={option.id}>
                    <li {...val} style={{ width: '100%' }}>
                      {option.name}
                    </li>
                    <DelOption onClick={() => delCondition(option.id)} />
                  </Flex>
                );
              }}
              options={conditions}
            />
            <Padding $width='12px' />
            {preset?.id ? (
              <CommonButton
                label='프리셋 수정'
                onClick={() => putCondition(preset?.id)}
                type='secondary'
              />
            ) : (
              <CommonButton
                label='프리셋 만들기'
                onClick={() => setShowNameModal(true)}
                type='secondary'
              />
            )}
          </Flex>
        </Flex>
        <ConfirmModal
          msg={confirm.msg}
          showModal={confirm.show}
          closeModal={() => setConfirm((prev) => ({ ...prev, show: false }))}
          clickConfirm={confirm.doConfirm}
        />
        <InputModal
          showModal={showNameModal}
          msg='이름을 입력해 주세요.'
          width='300px'
          onConfirm={clickSaveCondition}
          closeModal={() => setShowNameModal(false)}
        />
      </>
    );
  },
);

export default SearchSpecialCondArea;
SearchSpecialCondArea.displayName = 'SearchSpecialCondArea';

const DelOption = styled(Delete)`
  margin-right: 4px;

  &:hover {
    cursor: pointer;
  }
`;
