import styled, { css } from 'styled-components';

const colors = {
  primary: '#34568B',
  hoverBg: 'rgba(52,86,139,0.7)',
  selectedBg: 'rgba(52,86,139,0.14)',
};

const sizes = {
  HEAD01: '20px',
  HEAD02: '16px',
  normal: '14px',
};

const Flex = styled.div<{
  $height?: string;
  $alignItems?: string;
  $justify?: string;
  $padding?: string;
  $margin?: string;
  $border?: string;
  $borderBottom?: string;
  $backgroundColor?: string;
}>`
  height: ${({ $height }) => $height};
  display: flex;
  align-items: ${({ $alignItems }) => $alignItems};
  justify-content: ${({ $justify }) => $justify};
  padding: ${({ $padding }) => $padding};
  margin: ${({ $margin }) => $margin};
  border: ${({ $border }) => $border};
  border-bottom: ${({ $borderBottom }) => $borderBottom};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

Flex.defaultProps = {
  $alignItems: 'center',
};

const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

const Text = styled.div<{
  $width?: string;
  $margin?: string;
  $size?: string;
  $backcolor?: string;
  $color?: string;
  $center?: boolean;
  $hover?: boolean;
}>`
  width: ${({ $width }) => $width};
  font-size: ${({ $size }) => $size};
  margin: ${({ $margin }) => $margin};
  background-color: ${({ $backcolor }) => $backcolor};
  color: ${({ $color }) => $color};

  ${({ $hover }) =>
    $hover &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

  ${({ $center }) =>
    $center &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

Text.defaultProps = {
  $size: '14px',
  $center: false,
};

const Padding = styled.div<{ $val?: string; $width?: string }>`
  height: ${({ $val }) => $val};
  width: ${({ $width }) => $width};
`;

export { colors, sizes, Flex, FlexColumn, Text, Padding };
