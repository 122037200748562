import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Text } from '../../assets/styles';
import { useSelector } from 'react-redux';
import { IWoorimInitState } from '../../interfaces/common';

interface IProps {
  selectedVal: string[] | string;
  selCountry: (arg: string[] | string | null) => void;
  multiple?: boolean;
  showLabel?: boolean;
}

const SelectCountry = ({ selectedVal, selCountry, multiple = true, showLabel = true }: IProps) => {
  const countryMeta = useSelector((state: IWoorimInitState) => state.countries);
  const [val, setVal] = useState(
    multiple
      ? []
      : ({} as { id: string; label: string } | { id: string; label: string }[] | undefined),
  );
  const options = useMemo(() => {
    return countryMeta.map(({ code, country }) => ({
      label: `${country}(${code})`,
      id: code,
    }));
  }, [countryMeta]);

  useEffect(() => {
    if (typeof selectedVal === 'string') {
      setVal(options.find(({ id }) => id === selectedVal));
    } else {
      setVal(options.filter(({ id }) => selectedVal.includes(id)));
    }
  }, [selectedVal]);

  return (
    <>
      {showLabel && <Text $margin='0 8px 0 12px'>COUNTRY</Text>}
      <Autocomplete
        disablePortal
        multiple={multiple}
        value={val}
        id='combo-box-demo'
        options={options}
        sx={{ width: 250 }}
        onChange={(e, newVal: any) => {
          console.log(newVal);
          if (Array.isArray(newVal)) {
            selCountry(newVal.map(({ id }) => id));
          } else {
            selCountry(newVal.id);
          }
        }}
        renderInput={(params) => <TextField {...params} size='small' />}
      />
    </>
  );
};

export default SelectCountry;
